import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'


import './home.css'

export default  () => {
    const data = useStaticQuery(graphql`
    query {
      backaground:file(relativePath: {eq: "backgroundBanner.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return(
        <BackgroundImage
          className="masthed"
          fluid={data.backaground.childImageSharp.fluid}
          alt="Cappuccino"
        >
        <div className="main-container">
            <div className="home-box">
                <h2>Estamos Online!</h2>
                <p>Aproveite para curtir um café inesquecível Blendcerto com o sabor de Minas!! Nossos produtos são especializados para cafeterias, empresas, sorveterias, restaurantes e bares. Atendemos todo o Brasil</p>
                <Link to={"/product"}>
                  <button className="btn">
                      <p>Conheça nossos produtos</p>
                  </button>
                </Link>
            </div>
        </div>
        </BackgroundImage>
    )
};

import React from "react"

import Layout from "../components/Layout/layout"
import Home from '../components/Home/home';
import SEO from "../components/seo"

  const IndexPage = () => {
   
   return(
    <Layout>

      <SEO
        title="Home"
        description="Welcome!!"
       />
      <Home/>
    </Layout>
  )
}


export default IndexPage
